import React, { useEffect } from "react";
import { SFEmbeddedWindow as SFWindow } from "./LiveChat.types";

const initESW = (gslbBaseURL: string = ""): void => {
  if (typeof window === "undefined" && !(window as SFWindow)?.embedded_svc) {
    // this object will be generated by the script below, else it's broken
    return;
  }
  try {
    (window as SFWindow).embedded_svc.settings.displayHelpButton = true;
    (window as SFWindow).embedded_svc.settings.defaultMinimizedText =
      "Chat with Us";
    (window as SFWindow).embedded_svc.settings.loadingText = "Loading";
    (window as SFWindow).embedded_svc.settings.language = "en";
    (window as SFWindow).embedded_svc.settings.enabledFeatures = ["LiveAgent"];
    (window as SFWindow).embedded_svc.settings.entryFeature = "LiveAgent";
    (window as SFWindow).embedded_svc.settings.chatbotAvatarImgURL =
      "https://fashionphile.file.force.com/servlet/servlet.ImageServer?id=0153l0000067dGb&oid=00D1N000002r6Jz&lastMod=1687385192000";
    if (process.env.NEXT_PUBLIC_CHATBOT_CUSTOM_DOMAIN_ENABLED === "true") {
      (window as SFWindow).embedded_svc.settings.storageDomain = `${document.domain
        .split(".")
        .slice(-2)
        .join(".")}`;
    }
    (window as SFWindow).embedded_svc.init(
      `${process.env.NEXT_PUBLIC_CHATBOT_SF_URL}`,
      `${process.env.NEXT_PUBLIC_CHATBOT_HELP_URL}`,
      gslbBaseURL,
      `${process.env.NEXT_PUBLIC_CHATBOT_ORG_ID}`,
      "LiveChat",
      {
        baseLiveAgentContentURL: `${process.env.NEXT_PUBLIC_CHATBOT_CONTENT_URL}`,
        deploymentId: "5723l000000XdHh",
        buttonId: "5733l000000XdTJ",
        baseLiveAgentURL: `${process.env.NEXT_PUBLIC_CHATBOT_AGENT_URL}`,
        eswLiveAgentDevName:
          "EmbeddedServiceLiveAgent_Parent04I3l000000Kz02EAC_1755481a27a",
        isOfflineSupportEnabled: false,
      }
    );
  } catch (error) {
    console.error("Error initializing Salesforce integration:", error);
  }
};

export default (): React.ReactElement => {
  useEffect(() => {
    if ((window as SFWindow).embedded_svc) {
      initESW("https://service.force.com");
    } else {
      const s = document.createElement("script");
      s.setAttribute("src", `${process.env.NEXT_PUBLIC_CHATBOT_EMBEDDED_URL}`);
      s.onload = (): void => initESW();
      document.body.appendChild(s);
    }
  }, []);
  return null;
};
