import React, { createContext, useContext, useMemo } from "react";
import { FormType } from "../../@types/form";
import {
  GetPaymentMethods,
  getPaymentMethods,
} from "../../helpers/paymentMethods";

export type CurrencyDefaultOptions = Parameters<typeof Intl.NumberFormat>;
export interface CurrencyContextProps {
  formatter: Pick<ReturnType<typeof Intl.NumberFormat>, "format">;
  getAmountWithBonus: (amount: number, type: FormType) => number;
  getBonusAmount: (amount: number, type: FormType) => number;
  getBonusPercent: (
    type: FormType
  ) => GetPaymentMethods[typeof type]["bonusPercent"];
  getStoreCreditAndBonusFromTotalRequested: (
    totalRequestedStoreCreditAmount: number,
    totalPaymentNum: number
  ) => [number, number];
  getFee: (
    type: FormType
  ) => GetPaymentMethods[typeof type]["processingFeeAmount"];
  options: {
    defaultOptions: CurrencyDefaultOptions;
    locales: CurrencyDefaultOptions[0];
    formatOptions: CurrencyDefaultOptions[1];
  };
}
export const CurrencyContext = createContext<CurrencyContextProps>({
  formatter: {
    format: () => null,
  },
  getAmountWithBonus: () => 0,
  getBonusAmount: () => 0,
  getBonusPercent: () => 0,
  getStoreCreditAndBonusFromTotalRequested: () => [0, 0],
  getFee: () => 0,
  options: {
    defaultOptions: ["", {}],
    locales: "",
    formatOptions: {},
  },
});
export const getCurrencyContext = (): CurrencyContextProps =>
  useContext(CurrencyContext);

export const CurrencyProvider: React.FC = ({ children }) => {
  const feeData = getPaymentMethods();
  const locales = "en-US";
  const formatOptions: Intl.NumberFormatOptions = {
    style: "currency",
    currency: "USD",
  };
  const defaultOptions: CurrencyDefaultOptions = [locales, formatOptions];
  const formatter = Intl.NumberFormat(...defaultOptions);

  const getBonusPercent: CurrencyContextProps["getBonusPercent"] = (type) =>
    feeData?.[type]?.bonusPercent || 0;
  const getFee: CurrencyContextProps["getFee"] = (type) =>
    feeData?.[type]?.processingFeeAmount || 0;

  const getBonusAmount: CurrencyContextProps["getBonusAmount"] = (
    amount,
    type
  ) => {
    // NB bonusPercent is a whole number, not integers.
    // E.g. 10% === 10; 10% !== 0.1;
    const bonusPercent = getBonusPercent(type);
    const bonusAmount = amount * (bonusPercent / 100);
    const roundedBonus = Math.round((bonusAmount + Number.EPSILON) * 100) / 100;
    return roundedBonus;
  };

  const getAmountWithBonus: CurrencyContextProps["getAmountWithBonus"] = (
    amount,
    type
  ) => amount + getBonusAmount(amount, type);

  const getStoreCreditAndBonusFromTotalRequested: CurrencyContextProps["getStoreCreditAndBonusFromTotalRequested"] = (
    totalRequestedStoreCreditAmount: number,
    totalPaymentNum: number
  ): [number, number] => {
    const bonusPercent = getBonusPercent(FormType.STORE);
    const totalPaymentBonus = totalPaymentNum * bonusPercent * 0.01;

    if (totalPaymentNum + totalPaymentBonus < totalRequestedStoreCreditAmount) {
      return [totalPaymentNum, totalPaymentBonus];
    }

    const denominator = 1 + bonusPercent / 100;
    const storeCredit = totalRequestedStoreCreditAmount / denominator;
    const roundedStoreCredit = Math.round(storeCredit * 100) / 100;
    const bonus = storeCredit * bonusPercent * 0.01;
    const roundedBonus = Math.round(bonus * 100) / 100;

    return [roundedStoreCredit, roundedBonus];
  };

  const value = useMemo<CurrencyContextProps>(
    () => ({
      formatter,
      getAmountWithBonus,
      getBonusAmount,
      getBonusPercent,
      getStoreCreditAndBonusFromTotalRequested,
      getFee,
      options: {
        defaultOptions,
        locales,
        formatOptions,
      },
    }),
    [formatter, defaultOptions, locales, formatOptions]
  );

  return (
    <CurrencyContext.Provider value={value}>
      {children}
    </CurrencyContext.Provider>
  );
};
