import { Dispatch, SetStateAction, useState } from "react";

export type UseUpdateStateReturn<S> = [
  S,
  (newState: Partial<S>) => void,
  Dispatch<SetStateAction<S>>
];

export const useUpdateState = <S = unknown>(
  init: S
): UseUpdateStateReturn<S> => {
  const [state, setState] = useState<S>(init);
  const updateState = (newState: Partial<S>): void =>
    setState((state) => ({ ...state, ...newState }));
  return [state, updateState, setState];
};
