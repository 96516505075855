import React, { createContext, useContext, useEffect, useMemo } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { LoadUserRejectObj } from "../../@types/api";
import { loadUser } from "../../API/async";
import { useUpdateState } from "../../helpers/state";
import { ProfileType } from "../../@types/profile";
import { SegmentUserHOC } from "../../events/segment/segmentHOC";
import { emptyUser } from "./contextHelper";
import { TranslateProvider } from "./TranslateContext";

interface UserContextValue {
  user: ProfileType;
  loading: boolean;
  error: LoadUserRejectObj;
  userAddressValid: boolean;
  reloadUserContext: () => void;
  updateUser: (u: Partial<ProfileType>) => void;
}
const defaultState: UserContextValue = {
  user: emptyUser(),
  loading: true,
  error: [],
  userAddressValid: false,
  reloadUserContext: () => null,
  updateUser: () => null,
};

export const UserContext = createContext<UserContextValue>(defaultState);
export const getUserContext = (): UserContextValue => useContext(UserContext);

export const UserProvider: React.FC = ({ children }) => {
  const [userState, updateUserState] = useUpdateState<UserContextValue>(
    defaultState
  );
  const updateUser = (u: Partial<ProfileType>): void => {
    updateUserState({ user: { ...userState.user, ...u } });
  };

  const handleLoadUser = async (): Promise<void> => {
    try {
      const user = await loadUser();
      if (user) {
        updateUserState({ user });

        datadogRum.setUser({
          id: user.userId?.toString(),
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
        });
      }
    } catch (error) {
      if (error) {
        updateUserState({ error });
      }
    } finally {
      updateUserState({ loading: false });
    }
  };

  const reloadUserContext = (): void => {
    updateUserState(defaultState);
    handleLoadUser();
  };

  const userAddressValid =
    userState.user.address &&
    Object.values(userState.user.address).some(Boolean) &&
    !!userState.user.firstName &&
    !!userState.user.lastName &&
    userState.user.address.phone?.replace(/\D/g, "").length >= 10;

  useEffect(() => {
    handleLoadUser();
  }, []);

  const userStateMemo = useMemo<UserContextValue>(
    () => ({
      ...userState,
      updateUser,
      userAddressValid,
      reloadUserContext,
    }),
    [userState, updateUser, userAddressValid, reloadUserContext]
  );

  return (
    <SegmentUserHOC reloadUserContext={reloadUserContext}>
      <UserContext.Provider value={userStateMemo}>
        <TranslateProvider user={userStateMemo.user}>
          <div>{children}</div>
        </TranslateProvider>
      </UserContext.Provider>
    </SegmentUserHOC>
  );
};
