import React from "react";

const MobileAppContext = React.createContext<boolean | undefined>(undefined);

const useMobileAppData = (): boolean => {
  const context = React.useContext(MobileAppContext);
  if (context === undefined) {
    throw new Error("no data avaliable");
  }
  return context;
};

export { MobileAppContext, useMobileAppData };
