import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Script from "next/script";
import Cookies from "js-cookie";
import { FeatureFlagsProvider } from "../components/context/FeatureFlagsContext";
import { UserProvider } from "../components/context/UserContext";
import { CartContext } from "../components/context/CartContext";
import { CurrencyProvider } from "../components/context/CurrencyContext";
import { dataDogInit } from "../events/datadog";
import "../styles/main.scss";
import { loadSegment } from "../events/segment/segment";
import {
  COOKIES,
  getCookieDomain,
  REFERRER_COOKIE_OPTIONS,
} from "../events/cookies";
import LiveChat from "../components/LiveChat";
import { emptyCart } from "../components/context/contextHelper";
import { loadCart } from "../API/async";
import {
  mobileAppMountEvents,
  mobileAppDismountEvents,
} from "../events/mobileApp/mobileAppEvents";
import { MobileAppContext } from "../components/context/MobileAppContext";

const setupCookies = (): void => {
  const domain = getCookieDomain();

  Cookies.set(COOKIES.REDIRECT_URL, window.location.href, { domain });
};

const SupplierPortal = ({ Component, pageProps }): React.ReactElement => {
  const [cart, setCart] = useState(emptyCart);
  const [isMobileAppListening, setIsMobileAppListening] = useState(false);
  const router = useRouter();

  const isMobileApp =
    Cookies.get(COOKIES.MOBILE_APP) === "1" ||
    process?.env?.NEXT_PUBLIC_MOBILE_APP_FORCE === "true";

  useEffect(() => {
    if (router?.query?.referrer) {
      if (Cookies.get(COOKIES.REFERRER)) {
        Cookies.remove(COOKIES.REFERRER);
      }

      Cookies.set(
        COOKIES.REFERRER,
        `${router.query.referrer}`,
        REFERRER_COOKIE_OPTIONS
      );
    }
  }, [router?.query?.referrer]);

  useEffect(() => {
    dataDogInit();

    setupCookies();

    if (cart?.loading) {
      loadCart(setCart);
    }

    if (
      router.pathname === "/sell-with-us/virtual-appointment" ||
      router.asPath === "/sell-with-us/virtual-appointment"
    ) {
      router.push({ pathname: "/virtual-appointment" });
    }

    if (isMobileApp) {
      mobileAppMountEvents();
      setIsMobileAppListening(true);
      return () => {
        mobileAppDismountEvents();
        setIsMobileAppListening(false);
      };
    }
  }, []);

  return (
    <>
      <Script src="https://cdn.optimizely.com/js/18185992549.js" />
      {!cart?.loading && !isMobileApp && (
        <Script
          dangerouslySetInnerHTML={{
            __html: loadSegment(cart.isDNS),
          }}
          id="segment-script"
        />
      )}
      <FeatureFlagsProvider>
        <CartContext.Provider value={cart}>
          <UserProvider>
            <CurrencyProvider>
              <MobileAppContext.Provider value={isMobileAppListening}>
                <Component {...pageProps} />
              </MobileAppContext.Provider>
            </CurrencyProvider>
          </UserProvider>
        </CartContext.Provider>
      </FeatureFlagsProvider>

      {process.env.NEXT_PUBLIC_CHATBOT_ENABLED !== "false" && (
        <LiveChat embedded />
      )}
    </>
  );
};

export default SupplierPortal;
