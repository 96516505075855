import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

const env = process.env.NEXT_PUBLIC_ENV;

export enum DDLoggers {
  IMAGE_UPLOAD = "imageUploadLogger",
}

export const dataDogInit = (): void => {
  if (process.env.NEXT_PUBLIC_ENV === "dev") {
    return;
  }

  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_ID,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_TOKEN,
    site: "datadoghq.com",
    service: "seller-portal",
    env,
    version: process.env.NEXT_PUBLIC_DATADOG_VERSION,
    sampleRate: 100,
    trackInteractions: true,
  });

  if (process.env.NEXT_PUBLIC_DATADOG_RECORD === "true") {
    datadogRum.startSessionReplayRecording();
  }

  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DATADOG_TOKEN,
    service: "seller-portal",
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
    sampleRate: 100,
  });

  datadogLogs.createLogger(DDLoggers.IMAGE_UPLOAD, {
    level: "info",
    handler: "http",
    context: { env: process.env.NEXT_PUBLIC_ENV },
  });
};
