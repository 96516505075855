import Cookies from "js-cookie";

export enum COOKIES {
  GA = "_ga",
  PKJ = "port_key_j",
  UUID = "identityId",
  SEGMENT_UUID = "ajs_anonymous_id",
  SEGMENT_USER_ID = "ajs_user_id",
  REDIRECT_URL = "redirect_url",
  QUERY = "query",
  UTM_CAMPAIGN = "utm_campaign",
  REFERRER = "referrer",
  MOBILE_APP = "x-fp-app",
  MOBILE_DEVICE = "x-fp-os",
}

export const getCookieDomain = (): string => {
  if (typeof window !== "undefined") {
    const parts = window.location.hostname.split(".");
    parts[0] = "";
    return parts.join(".");
  }
  return "";
};

interface PKJ {
  exp: number; // time in ms
  iat: number; // time in ms
  iss: string;
  jti: string;
  nbf: number; // time in ms
  prv: string;
  sub: number;
}

const parsePKJ = (pkj?: string): PKJ => {
  const pkjSlice = (pkj ?? Cookies.get(COOKIES.PKJ))?.split(".")[1];
  if (!pkjSlice) {
    console.error("PKJ parsing failed");
    return null;
  }
  return JSON.parse(atob(pkjSlice));
};

export const pkjExpired = (pkj?: string): boolean => {
  const parsed = parsePKJ(pkj);
  return parsed ? parsed.exp < +new Date() : true;
};

const REFERRER_COOKIE_EXP_DAYS = process.env
  .NEXT_PUBLIC_REFERRER_COOKIE_EXP_DAYS
  ? parseInt(process.env.NEXT_PUBLIC_REFERRER_COOKIE_EXP_DAYS, 10)
  : 7;

const today = new Date();
export const REFERRER_COOKIE_OPTIONS = {
  path: "/",
  expires: new Date(today.setDate(today.getDate() + REFERRER_COOKIE_EXP_DAYS)),
  domain: getCookieDomain(),
};
