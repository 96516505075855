import { Path } from "react-hook-form";
import { AccordionProps } from "../components/Accordion/Accordion.props";
import { SellerAddress } from "./address";
import { PaymentDisplayInformation, PaymentFormInformation } from "./payment";
import { DeepPartial } from "./util";

export enum SavedFormType {
  CHECK = "check",
  DIRECT_DEPOSIT = "ach",
  NM_CARD = "nm_gift_card",
  NM_CARD_GTE_THRESHOLD = "nm_gift_card_gte_threshold",
  PAYPAL = "paypal",
  WIRE_TRANSFER = "wire_transfer",
}

export enum ReadonlyFormType {
  ADDRESS = "address",
  STORE = "account_balance",
  USER = "user",
}

export type FormType = SavedFormType | ReadonlyFormType;
// eslint-disable-next-line no-redeclare
export const FormType = {
  ...SavedFormType,
  ...ReadonlyFormType,
};

export const FormLabel: { [key in FormType]?: string } = {
  [FormType.DIRECT_DEPOSIT]: "Direct Deposit",
  [FormType.WIRE_TRANSFER]: "Wire Transfer",
  [FormType.PAYPAL]: "Paypal",
  [FormType.CHECK]: "Check",
  [FormType.STORE]: "Store",
  [FormType.NM_CARD]: "Neiman Marcus Gift Card",
};

export interface FormDataTypes extends Record<FormType, object> {
  [FormType.ADDRESS]: SellerAddress;
  [FormType.USER]: {
    firstName: string;
    lastName: string;
    email: string;
    address: SellerAddress;
  };
  [FormType.CHECK]: {
    nameOnCheck: string;
    address: SellerAddress;
  };
  [FormType.DIRECT_DEPOSIT]: {
    bankName: string;
    accountNumber: number;
    routingNumber: number;
  };
  [FormType.NM_CARD]: {
    email: string;
  };
  [FormType.NM_CARD_GTE_THRESHOLD]: {
    email: string;
  };
  [FormType.PAYPAL]: {
    email: string;
  };
  [FormType.STORE]: {
    email: string;
  };
  [FormType.WIRE_TRANSFER]: {
    bankName: string;
    address: SellerAddress;
    accountNumber: number;
    swiftCode: string;
    nameOnAccount: string;
  };
}

export type FormDataType<FT extends FormType = FormType> = FormDataTypes[FT];

export interface FormValidationError<FT extends FormType = FormType> {
  msg: string;
  param: Path<FormDataType<FT>>;
  location: string;
  value?: unknown;
}

export type FormStateBaseError<FT extends FormType> =
  | string
  | FormValidationError<FT>
  | FormValidationError<FT>[];

export interface FormStateBase<FT extends FormType = FormType> {
  errorMessage: FormStateBaseError<FT>;
  loading: boolean;
}

export interface FormProps<FT extends FormType> extends FormStateBase<FT> {
  onReset?: () => void;
  onSubmit?: (options?: FormDataType<FT>) => void | Promise<void>;
}

export const FormDataDefault: Record<FormType, () => FormDataType> = {
  [FormType.ADDRESS]: () => ({
    address1: "",
    city: "",
    country: "",
    postalCode: "",
    state: "",
  }),
  [FormType.USER]: () => ({
    firstName: "",
    lastName: "",
    email: "",
    address: {
      address1: "",
      city: "",
      country: "",
      postalCode: "",
      state: "",
    },
  }),
  [FormType.CHECK]: () => ({
    nameOnCheck: "",
    address: {
      phone: "",
      address1: "",
      city: "",
      country: "",
      postalCode: "",
      state: "",
    },
  }),
  [FormType.DIRECT_DEPOSIT]: () => ({
    bankName: "",
    accountNumber: undefined,
    routingNumber: undefined,
  }),
  [FormType.NM_CARD]: () => ({
    email: "",
  }),
  [FormType.NM_CARD_GTE_THRESHOLD]: () => ({
    email: "",
  }),
  [FormType.PAYPAL]: () => ({
    email: "",
  }),
  [FormType.STORE]: () => ({
    email: "",
  }),
  [FormType.WIRE_TRANSFER]: () => ({
    bankName: "",
    address: {
      address1: "",
      city: "",
      country: "",
      postalCode: "",
      state: "",
    },
    accountNumber: undefined,
    swiftCode: "",
    nameOnAccount: "",
  }),
};

export interface PaymentFormState<FT extends FormType = FormType> {
  transferAmount: number;
  paymentInformation: PaymentFormInformation<FT>;
  paymentData?: FormDataType;
}
export interface PaymentDisplayState<FT extends FormType = FormType> {
  transferAmount: number;
  paymentInformation: PaymentDisplayInformation<FT>;
}

export interface PaymentFormProps<FT extends FormType = FormType>
  extends FormProps<FT>,
    PaymentFormState<FT> {
  onSubmit?: (data: Partial<FormDataType>) => Promise<void>;
}

export interface PaymentFormFunctions
  extends Pick<AccordionProps, "onClose" | "onOpen" | "expand" | "onAction"> {
  onValidChange?: (isValid: boolean) => void;
  onSubmit?: (data: DeepPartial<PaymentFormState>, override?: boolean) => void;
}
