import React from "react";
import axios, { AxiosError, AxiosPromise } from "axios";
import useSWR from "swr";
import { captureException, captureMessage } from "@sentry/nextjs";
import Cookies from "js-cookie";
import {
  APIResponse,
  LoadUserRejectObj,
  RejectObj,
  GetFeatureFlagResponse,
  SubmitShipListBody,
  SubmitShipListResponse,
  GetDesignerDirectoryResponse,
} from "../@types/api";
import { Brands } from "../@types/brands";
import { ProfileType } from "../@types/profile";
import { emptyUser } from "../components/context/contextHelper";
import { SellerAddress } from "../@types/address";
import { PendingItem } from "../@types/PendingItem";
import SYBForm from "../@types/syb-form";
import { COOKIES } from "../events/cookies";
import { CartType } from "../@types/cart";
import { AccountType } from "../@types/account";
import { transformAccountToProfile } from "../helpers/profile/transformAccountToProfile";
import {
  accountURL,
  designerDirectoryUrl,
  featureFlagURL,
  shiplistApiURL,
  addressesURL,
  webappAPI,
  spAPI,
} from "./urls";
import axiosInstance, { axiosInstanceNoAuth } from "./axios-config";

// bare axios (no instances, no headers) bc headers freakout API gateway for some reason
export const getDesignerDir = async (): Promise<Brands> => {
  try {
    const result = await axiosInstanceNoAuth.get<
      GetDesignerDirectoryResponse[]
    >(designerDirectoryUrl);

    const brands: Brands = result.data.reduce((brand, designer) => {
      brand[designer.id] = { name: designer.name };

      if (designer.brand_categories) {
        brand[designer.id].categories = designer.brand_categories.map(
          (category) => ({
            id: category.id,
            name: category.name,
          })
        );
      }

      return brand;
    }, {});

    return brands;
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const getFeatureFlag = async (key: string): Promise<boolean> => {
  try {
    const result = await axiosInstanceNoAuth.post<GetFeatureFlagResponse>(
      featureFlagURL,
      { key }
    );
    return result.data.isEnabled;
  } catch (error) {
    captureException("getFeatureFlag", error);
    return false;
  }
};

export const submitShipList = (
  data: Partial<SubmitShipListBody>
): Promise<SubmitShipListResponse> =>
  axiosInstance
    .post<SubmitShipListResponse>(shiplistApiURL, data)
    .then(({ data }) => {
      if ("success" in data && !data.success) {
        let errorMessage = "";
        if ("errors" in data) {
          if (!data?.errors?.message) {
            Object.keys(data.errors).forEach((key) => {
              if (Array.isArray(data.errors[key])) {
                errorMessage += `${(data.errors[key] as string[]).join(" ")} `;
              } else {
                errorMessage += `${data.errors[key]} `;
              }
            });
          }
        }
        captureMessage(`submitShipList error: ${JSON.stringify(data)}`);
        errorMessage = errorMessage.trim() || "An error occurred.";
        throw new Error(errorMessage);
      }
      return data;
    });

export const getShiplist = (): APIResponse<PendingItem[]> => {
  const { data, error, isValidating } = useSWR<
    { data: { data: PendingItem[] } },
    AxiosError
  >(shiplistApiURL, axiosInstance.get, {
    errorRetryCount: 0,
    revalidateOnFocus: false,
  });
  const pendingItems = data?.data?.data || [];
  // const pendingItems = pendingItemFixture;
  return {
    data: pendingItems,
    isLoading: !error && !data && isValidating,
    error,
  };
};

const handleRejectObj = <K extends string>(
  err: AxiosError,
  rejectObj: RejectObj<K>,
  key: K,
  msgContext: string,
  type?: string
): void => {
  const errJson: { status?: number; message?: string } =
    err?.toJSON?.() || err || {};
  rejectObj.push({
    key,
    message: err?.message || errJson?.message || `Couldn't load ${msgContext}`,
    status: `${errJson?.status}` || "400",
    type,
  });
};

export const loadUser = async (): Promise<ProfileType> =>
  new Promise(
    // eslint-disable-next-line no-async-promise-executor
    async (res, rej): Promise<void> => {
      let user = emptyUser();
      const rejectObj: LoadUserRejectObj = [];
      try {
        const response = await axiosInstance.get(accountURL);
        if (!response?.data?.data) {
          rej();
          return;
        }

        const account: AccountType = response.data.data;
        const profile: ProfileType = transformAccountToProfile(account);

        const primaryAddressInclude = response.data.included?.find(
          (include) => include.type === "primaryAddress"
        );
        if (primaryAddressInclude) {
          user.address = primaryAddressInclude.attributes as SellerAddress;
        }

        user = { ...user, ...profile };
      } catch (error) {
        handleRejectObj(error, rejectObj, "user", "user data");
      }

      // only rej on a failed initial user load, not on payment or address
      if (rejectObj?.find((o) => o?.key === "user")) {
        rej(rejectObj);
        captureException("loadUser", {
          extra: { rejectObj: JSON.stringify(rejectObj) },
        });
      } else {
        res(user);
      }
    }
  );

export const loadCart = async (
  setCart: React.Dispatch<React.SetStateAction<CartType>>
): Promise<void> => {
  const identityId = Cookies.get(COOKIES.UUID);
  const pkj = Cookies.get(COOKIES.PKJ);

  try {
    const { data } = await axios({
      method: "get",
      url: `${process.env.NEXT_PUBLIC_NODE_APP_URL}/cart`,
      headers: {
        Accept: "application/x.fashionphile.v1+json",
        "x-fp-identityid": identityId,
        "x-fp-user-agent": process.env.NEXT_PUBLIC_FP_USER_AGENT,
        ...(pkj ? { Authorization: `Bearer ${pkj}` } : {}),
      },
    });

    const inStockItems =
      data?.data?.cartItems?.filter((cartItem) => cartItem.isSold === false) ??
      [];

    setCart({
      loading: false,
      isLoggedIn: !!pkj,
      cartAmount: inStockItems.length,
      isDNS: data?.data?.user?.dns ?? false,
    });
  } catch (err) {
    captureException("loadCart", err);
  }
};

/* NB 'phone' must be a pure numeric-string */
export const updateAddress = async (
  params: SellerAddress
): Promise<AxiosPromise> => {
  const json = await axiosInstance.post<SellerAddress>(addressesURL, params);
  return json;
};

export const saveDraft = async (
  draftId: number,
  userEmail: string,
  values: SYBForm
): Promise<{ data: { id: number } }> => {
  const { currentBrand, currentCategory, itemName, description } = values;

  const data: Record<string, string | number> = {
    email: userEmail,
    name: itemName,
    brandId: currentBrand,
  };

  if (
    currentCategory &&
    !/select/i.test(currentCategory) &&
    currentCategory !== "undefined"
  ) {
    data.categoryId = currentCategory;
  }
  if (description && description.length > 0) {
    data.description = description;
  }
  if (draftId) {
    data.id = draftId;
  }

  return axiosInstanceNoAuth.put(`${spAPI}/quotes/drafts`, data);
};

export const getProductSuggestions = (
  search: string
): Promise<{ data: string[]; status: number; statusText: string }> =>
  axiosInstance.get(`${webappAPI}/products/suggest?search=${search}`);
