import { EventTypes } from "./mobileAppEvents.types";

const flutterChannelCall = (channel: string, payload: object): void => {
  if (process?.env?.NEXT_PUBLIC_ENV !== "prod") {
    console.log(JSON.stringify({ channel, payload }));
  }
  if ((window as any).flutter_inappwebview) {
    (window as any).flutter_inappwebview
      .callHandler(channel, payload)
      .then((result: any): void => {
        if (process?.env?.NEXT_PUBLIC_ENV !== "prod") {
          console.log(JSON.stringify(result));
        }
      });
  }
};

const eventLogin = (): void => {
  flutterChannelCall("login", {
    action: "login-start",
  });
};

const eventLoadingStart = (): void => {
  flutterChannelCall("loading", { action: "loading-start" });
};

const eventLoadingFinish = (): void => {
  flutterChannelCall("loading", { action: "loading-finish" });
};

const eventSellerOpen = (): void => {
  flutterChannelCall("seller", { action: "seller-open" });
};

const eventLocationOpen = (): void => {
  flutterChannelCall("seller", { action: "location-open" });
};

const eventSellerUrl = (e: any): void => {
  flutterChannelCall("seller", { ...e.detail, action: "seller-open-url" });
};

const eventOpenShopUrl = (e: any): void => {
  flutterChannelCall("openshopurl", { ...e.detail, action: "open-shop-url" });
};

const eventExternalOpenUrl = (e: any): void => {
  flutterChannelCall("openurl", {
    ...e.detail,
    action: "external-open-url",
  });
};

const eventOpenMyAccountUrl = (e: any): void => {
  flutterChannelCall("openmyaccounturl", {
    ...e.detail,
    action: "open-myaccount-url",
  });
};

const eventAnalyticsTrackEvent = (e: any): void => {
  flutterChannelCall("analytics", {
    action: "track",
    ...e.detail,
  });
};

const eventAnalyticsPageViewEvent = (e: any): void => {
  flutterChannelCall("analytics", {
    action: "page-view",
    event: "Page Viewed",
    ...e.detail,
  });
};

export const mobileAppMountEvents = (): void => {
  window.addEventListener(EventTypes.LOGIN, eventLogin);
  window.addEventListener(EventTypes.LOADING_START, eventLoadingStart);
  window.addEventListener(EventTypes.LOADING_FINISH, eventLoadingFinish);
  window.addEventListener(EventTypes.SELLER_OPEN, eventSellerOpen);
  window.addEventListener(EventTypes.LOCATION_OPEN, eventLocationOpen);
  window.addEventListener(EventTypes.SELLER_URL, eventSellerUrl);
  window.addEventListener(EventTypes.OPEN_SHOP_URL, eventOpenShopUrl);
  window.addEventListener(EventTypes.EXTERNAL_OPEN_URL, eventExternalOpenUrl);
  window.addEventListener(
    EventTypes.OPEN_MY_ACCOUNT_URL,
    eventOpenMyAccountUrl
  );
  window.addEventListener(
    EventTypes.ANALYTICS_TRACK_EVENT,
    eventAnalyticsTrackEvent
  );
  window.addEventListener(
    EventTypes.ANALYTICS_PAGE_VIEW_EVENT,
    eventAnalyticsPageViewEvent
  );
};

export const mobileAppDismountEvents = (): void => {
  window.removeEventListener(EventTypes.LOGIN, eventLogin);
  window.removeEventListener(EventTypes.LOADING_START, eventLoadingStart);
  window.removeEventListener(EventTypes.LOADING_FINISH, eventLoadingFinish);
  window.removeEventListener(EventTypes.SELLER_OPEN, eventSellerOpen);
  window.removeEventListener(EventTypes.LOCATION_OPEN, eventLocationOpen);
  window.removeEventListener(EventTypes.SELLER_URL, eventSellerUrl);
  window.removeEventListener(EventTypes.OPEN_SHOP_URL, eventOpenShopUrl);
  window.removeEventListener(
    EventTypes.EXTERNAL_OPEN_URL,
    eventExternalOpenUrl
  );
  window.removeEventListener(
    EventTypes.OPEN_MY_ACCOUNT_URL,
    eventOpenMyAccountUrl
  );
  window.removeEventListener(
    EventTypes.ANALYTICS_TRACK_EVENT,
    eventAnalyticsTrackEvent
  );
  window.removeEventListener(
    EventTypes.ANALYTICS_PAGE_VIEW_EVENT,
    eventAnalyticsPageViewEvent
  );
};
