export const EventTypes = {
  LOADING_START: "fpLoadingStart",
  LOADING_FINISH: "fpLoadingFinish",
  LOGIN: "fpLogin",
  SELLER_OPEN: "fpSellerOpen",
  SELLER_URL: "fpSellerUrl",
  OPEN_SHOP_URL: "fpOpenShopUrl",
  EXTERNAL_OPEN_URL: "fpExternalOpenUrl",
  OPEN_MY_ACCOUNT_URL: "fpOpenMyAccountUrl",
  LOCATION_OPEN: "fpLocationOpen",
  ANALYTICS_TRACK_EVENT: "fpAnalyticsTrackEvent",
  ANALYTICS_PAGE_VIEW_EVENT: "fpAnalyticsPageViewEvent",
};
