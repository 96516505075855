/* eslint-disable no-throw-literal */
import axios, { AxiosError, AxiosResponse } from "axios";
import { captureException } from "@sentry/nextjs";
import Cookies from "js-cookie";
import { COOKIES } from "../events/cookies";

const gaCookie = Cookies.get(COOKIES.GA);

export const standardHeaders = {
  accept: "application/x.fashionphile.v1+json",
  "x-fp-user-agent": process.env.NEXT_PUBLIC_FP_USER_AGENT,
};

if (gaCookie) {
  standardHeaders["x-ga"] = gaCookie;
}

const DefaultError =
  "It’s not you, it's us! An error occurred on our end. Please try again.";

interface MappedError {
  message: string;
  status: number;
  raw: any;
}
const mapErrorObj = (err: any): string => {
  if (typeof err === "string") {
    return err;
  }
  if (Array.isArray(err)) {
    return err[0];
  }
  if (typeof err === "object") {
    if (err?.msg) {
      if (err?.param) {
        return `${err.msg}: ${err.param}`;
      }
      return err.msg;
    }
    if (err.statusText) {
      if (err?.status || err?.status_code) {
        const status = err.status || err.status_code;
        return `${status}: ${err.statusText}`;
      }
      return err.statusText;
    }
    if (err?.message) {
      return err.message;
    }
    return (Object.values(err)?.[0] as any) || err;
  }
  return DefaultError;
};
const errorMapValidator = (error: any): MappedError => {
  if (axios.isCancel(error)) {
    return;
  }

  const raw: unknown = error;

  if (error?.message && error?.status) {
    throw {
      ...error,
      raw,
    };
  }

  let msg: string;
  let status: number;

  if ("data" in error) {
    msg = mapErrorObj(error.data);
    status = error?.status;
  } else if ("errors" in error) {
    let err = error?.errors;
    if (Array.isArray(error.errors)) {
      err = error?.errors[0];
    }
    msg = mapErrorObj(err);
    status = err?.status;
  } else if (error?.response?.data) {
    msg = mapErrorObj(error.response.data);
    status = error.response?.status;
  } else if (error?.response) {
    msg = mapErrorObj(error.response);
    status = error.response?.status;
  } else if (error?.toJSON?.()?.errors) {
    msg = mapErrorObj(error.toJSON().errors);
    status = error.toJSON()?.status;
  } else if (error?.toJSON) {
    msg = mapErrorObj(error.toJSON());
    status = error.toJSON()?.status;
  } else if (error?.errors) {
    msg = mapErrorObj(error.errors);
    status = error?.status || error.errors?.status;
  } else {
    msg = mapErrorObj(error);
    status = error?.status;
  }

  throw {
    message: msg || DefaultError,
    status: (status && (typeof status === "string" ? +status : status)) || 400,
    raw,
  };
};

export const genericErrorHandler = (error: AxiosError): void | MappedError => {
  const mappedError = errorMapValidator(error);
  if (mappedError) {
    captureException(error);
    throw mappedError;
  }
};

export const genericSuccessHandler = (res: any): AxiosResponse => {
  if ("success" in res && !res.success) {
    // just checking `!res?.success` will throw a false-positive
    throw res;
  }
  return res;
};
