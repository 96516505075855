import React, { useCallback, useEffect, useMemo } from "react";
import Cookies from "js-cookie";
import { v4 as uuidV4 } from "uuid";
import { COOKIES, getCookieDomain, pkjExpired } from "../cookies";
import { resetSegment } from "./segment";

interface Props {
  reloadUserContext: () => void;
}

export const SegmentUserHOC: React.FC<Props> = ({
  children,
  reloadUserContext,
}) => {
  const cookies = Cookies.get();
  const pkj = useMemo(() => Cookies.get(COOKIES.PKJ), [cookies]);
  const userUUID = useMemo(() => Cookies.get(COOKIES.UUID), [cookies]);

  const setUUID = useCallback(() => {
    Cookies.set(COOKIES.UUID, uuidV4(), {
      expires: 365,
      domain: getCookieDomain(),
    });
  }, []);

  useEffect(() => {
    const segmentUUID = JSON.parse(
      window.localStorage?.getItem(COOKIES.SEGMENT_UUID)
    ) as string;
    const segmentUserId = JSON.parse(
      window.localStorage?.getItem(COOKIES.SEGMENT_USER_ID)
    ) as string;

    if (!userUUID) {
      setUUID();
    } else if (pkj && !pkjExpired() && !segmentUserId) {
      reloadUserContext();
    } else if ((!pkj && segmentUserId) || userUUID !== segmentUUID) {
      resetSegment(userUUID);
    }
  }, [cookies]);

  return <>{children}</>;
};
