/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef } from "react";
import { Icon } from "@fashionphile/component-library";
import { SFPopupWindow as SFWindow } from "./LiveChat.types";

const DEPLOYMENT_ID = "5723l000000XdHh";
const ORG_ID = "00D1N000002r6Jz";
const LA_SCRIPT_URL =
  "https://c.la4-c3-ph2.salesforceliveagent.com/content/g/js/57.0/deployment.js";

const BUTTON_ID = "5733l000000XdTJ";
const BUTTON_ONLINE_EL_ID = `liveagent_button_online_${BUTTON_ID}`;
const BUTTON_OFFLINE_EL_ID = `liveagent_button_offline_${BUTTON_ID}`;

/** magic snippet from SF docs */
const onloadLA = (): void => {
  if (typeof window === "undefined") {
    return;
  }
  (window as SFWindow)?.liveagent.init(
    "https://d.la4-c3-ph2.salesforceliveagent.com/chat",
    DEPLOYMENT_ID,
    ORG_ID
  );

  if (!(window as SFWindow)._laq) {
    (window as SFWindow)._laq = [];
  }

  (window as SFWindow)._laq.push(() => {
    (window as SFWindow).liveagent.showWhenOnline(
      BUTTON_ID,
      document.getElementById(BUTTON_ONLINE_EL_ID)
    );
    (window as SFWindow).liveagent.showWhenOffline(
      BUTTON_ID,
      document.getElementById(BUTTON_OFFLINE_EL_ID)
    );
  });
};

export default (): React.ReactElement => {
  const buttonRef = useRef<HTMLButtonElement>();
  useEffect(() => {
    if ((window as SFWindow).liveagent) {
      onloadLA();
    } else {
      const s = document.createElement("script");
      s.setAttribute("src", LA_SCRIPT_URL);
      s.onload = onloadLA;
      document.body.appendChild(s);
    }
  }, []);

  const startChat = (): void => {
    if (typeof window === "undefined") {
      return;
    }
    if (buttonRef.current) {
      buttonRef.current.setAttribute("aria-hidden", "false");
    }
    (window as SFWindow).liveagent.startChat(BUTTON_ID);
  };

  // NB liveagent scripts will modify element styles, ie display: 'none' below
  return (
    <>
      <button
        ref={(ref): void => {
          if (ref) {
            buttonRef.current = ref;
          }
        }}
        type="button"
        className="liveagent-button"
        id={BUTTON_ONLINE_EL_ID}
        style={{ display: "none" }}
        onClick={startChat}
        aria-hidden="true"
      >
        <div className="liveagent-button-centering">
          <Icon name="icon-comment" size="lg" className="liveagent-icon" />
          <span>Chat with Us</span>
        </div>
      </button>
      <div
        className="liveagent-button"
        id={BUTTON_OFFLINE_EL_ID}
        style={{ display: "none" }}
      >
        <span>Chat is offline</span>
      </div>
    </>
  );
};
