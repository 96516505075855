import { CartType } from "../../@types/cart";
import { ProfileType } from "../../@types/profile";

export const emptyUser = (): ProfileType => ({
  id: 0,
  balance: 0,
  userId: null,
  email: "",
  firstName: "",
  lastName: "",
  address: {
    phone: "",
    address1: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  },
  banned: false,
  sellerBanned: false,
  createdAt: "",
  updatedAt: "",
});

export const emptyCart: CartType = {
  loading: true,
  isLoggedIn: false,
  cartAmount: 0,
  isDNS: false,
};

export const parseUser = (data: any, user?: ProfileType): ProfileType => {
  if (!data?.id) {
    return emptyUser();
  }

  let parsedUser: ProfileType;
  if (data?.email || data?.id) {
    // new / EU api
    parsedUser = { ...user, ...data };
  } else if (data?.data?.attributes) {
    // web-app / US API
    parsedUser = { ...user, ...data.data.attributes };
  } else if (data?.included?.[0]?.attributes) {
    // old code / ??
    parsedUser = {
      ...user,
      ...data.included[0].attributes,
    };
  } else {
    console.warn("could not parse fetched user");
  }

  if (!parsedUser?.id) {
    console.error("No user ID found");
    return emptyUser();
  }

  return parsedUser;
};
