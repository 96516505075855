/* eslint-disable prettier/prettier */
export const translations = {
  'fr': {
    'Designer': 'Designer',
    'Select a Designer': 'Sélectionner un Designer',
    'Please select a designer': 'Entrée Designer manquante',
    'Category': 'Catégorie',
    'Select a category': 'Sélectionner une catégorie',
    'Please select a category': 'Entrée de catégorie manquante',
    'Item details and condition': 'Détails et état de l’article',
    'Enter item details and condition ': 'Entrée de description manquante',
    'Item/product name': 'Nom de l’article/du produit',
    'Enter item/product name ': 'Entrée de nom d’article/produit manquante',
    'Invalid File Format': 'Format de fichier non valide',
    'Oops something went wrong. Please try again.': 'Oups, quelque chose a mal tourné. Veuillez réessayer.',
    'Add photo': 'Ajouter une photo',
    'Add a photo': 'Ajouter une photo',
    'Designer directory': 'Répertoire du concepteur',
    'Terms & conditions': 'Termes et conditions',
    'Privacy policy': 'Politique de confidentialité',
    'Change language': 'Changer la langue',
    'English (EN)': 'Anglais (EN)',
    'French (FR)': 'Français (FR)',
    'Sign in or register': 'Se connecter ou s\'enregistrer',
    'Your photos are still uploading. Please submit your quote after they are finished uploading.': 'Vos photos sont toujours en cours de téléchargement. Veuillez soumettre votre devis une fois le téléchargement terminé.',
    'You must upload at least 1 image': 'Vous devez télécharger au moins 1 image',
    'Welcome!': 'Bienvenue !',
    'Email': 'E-mail',
    'Not you?': 'Pas vous ?',
    'Sign in to My Account': 'Connectez-vous à Mon compte',
    'What are you selling?': 'Que vendez-vous?',
    'Any details we should know about?': 'Des détails que nous devrions connaître?',
    'For example:': 'Par exemple :',
    'Condition: light corner wear, odor, interior pen marks': 'Condition: usure légère du coin, odeur, marques de stylo intérieures',
    'Items included: shoulder strap and lock & key': 'Articles inclus: bandoulière et serrure et clé',
    'Other details: measurements & item age': 'Autres détails: mesures et âge de l’article',
    'Upload photos of your item': 'Télécharger des photos de votre objet',
    'Show us your best shots! Photograph one item at a time on a solid white/color background and follow our image prompts.': 'Montrez-nous vos meilleurs clichés! Photographiez un élément à la fois sur un fond blanc / couleur uni et suivez nos instructions d’image.',
    'Please select a category to begin uploading photos': 'Veuillez sélectionner une catégorie pour commencer à télécharger des photos',
    'Submit My Quote': 'Soumettre mon devis',
    'Front': 'Devant',
    'Back': 'Retour',
    'Inside': 'Intérieur',
    'Base': 'Base',
    'Condition': 'Condition',
    'Details': 'Détails ',
    'Retail tag or sticker': 'Étiquette ou autocollant de vente au détail',
    'Serial or designer ID': 'ID de série ou de Designer',
    'Engraving Detail': 'Détail de gravure',
    'Certificate or Paperwork': 'Certificat ou documents',
    'Top': 'Haut',
    'Side': 'Côté',
    'Sole': 'Seul',
    'Retail Tag or Sticker': 'Étiquette ou autocollant de vente au détail',
    'Other Angles': 'Autres angles',
    'Start a Quote': 'Commencer un devis',
    'Already have an account?': 'Vous avez déjà un compte ?',
    'Sign in': 'Connexion',
  },
  'options': {
    'plural_rule': 'n > 1',
    'plural_number': '2',
  },
};
