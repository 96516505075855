export enum SupportedRegion {
  EU = "EU",
  US = "US",
}

export enum SupportedLocale {
  EN = "en",
  FR = "fr",
}

export const isRegionEU = process.env.NEXT_PUBLIC_REGION === SupportedRegion.EU;
