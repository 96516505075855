/* eslint-disable prefer-promise-reject-errors */
import { AxiosRequestConfig, AxiosResponseTransformer } from "axios";
import Cookies from "js-cookie";
import { AxiosResponseWithHeaders } from "../@types/axios";
import { COOKIES, getCookieDomain } from "../events/cookies";

export const setAuthOnRequest = (
  req: AxiosRequestConfig
): AxiosRequestConfig => {
  const controller = new AbortController();
  const token = Cookies.get("port_key_j");

  if (!token) {
    controller.abort();
  }

  req.headers.authorization = `Bearer ${token}`;
  return { ...req, signal: controller.signal };
};

export type GetAuthResponse = AxiosResponseWithHeaders<{
  authorization?: string;
  "x-amzn-remapped-authorization"?: string;
}>;
export const getAuthFromResponse: AxiosResponseTransformer = (
  data,
  headers
) => {
  if (headers) {
    let newAuthToken: string = null;
    if ("authorization" in headers) {
      newAuthToken = headers.authorization;
    }
    if ("x-amzn-remapped-authorization" in headers) {
      newAuthToken = headers["x-amzn-remapped-authorization"];
    }
    if (newAuthToken) {
      newAuthToken = newAuthToken.split(" ").pop();
      const domain = getCookieDomain();
      Cookies.set(COOKIES.PKJ, newAuthToken, { domain });
    }
  }
  let parsedData;
  try {
    parsedData = JSON.parse(data);
  } catch (e) {
    parsedData = {};
  }
  return parsedData;
};
