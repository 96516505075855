export const spAPI = process.env.NEXT_PUBLIC_NODE_APP_URL;
export const webappAPI = process.env.NEXT_PUBLIC_API_URL;
export const euAPI = process.env.NEXT_PUBLIC_EU_URL;
export const regionEU = process.env.NEXT_PUBLIC_REGION === "EU";

export const sellersURL = `${spAPI}/sellers`;
export const accountURL = `${webappAPI}/account?include=primaryAddress`;
export const addressesURL = `${webappAPI}/addresses`;
export const transactionsURL = `${sellersURL}/purchaseOrders`;
export const quotesURL = `${sellersURL}/quotes?includes=images,status,seller,brand,category,purchase_order_detail`;
export const shiplistApiURL = `${webappAPI}/ship-list`;
export const featureFlagURL = `${webappAPI}/snippets/feature-flag`;
export const designerDirectoryUrl = `${webappAPI}/v2/brand-categories`;
