import React from "react";
import LiveChatEmbedded from "./LiveChatEmbedded";
import LiveChatPopup from "./LiveChatPopup";

/** Returns embedded > popup */
export default ({
  embedded,
  popup,
}: Record<string, boolean>): React.ReactElement => {
  if (embedded) {
    return <LiveChatEmbedded />;
  }
  if (popup) {
    return <LiveChatPopup />;
  }
  console.warn("LiveChat component not called with one of the available types");
  return null;
};
