import axios from "axios";
import Cookies from "js-cookie";
import { getAuthFromResponse, setAuthOnRequest } from "./authInterceptor";
import {
  genericErrorHandler,
  genericSuccessHandler,
  standardHeaders,
} from "./axios-base";

export const axiosInstanceOptionalAuth = axios.create({
  transformResponse: getAuthFromResponse,
  headers: { ...standardHeaders },
});

axiosInstanceOptionalAuth.interceptors.request.use((config) => {
  const authJWT: string = Cookies.get("port_key_j");
  if (authJWT) {
    config.headers.Authorization = `Bearer ${authJWT}`;
  }
  return config;
}, genericErrorHandler);

axiosInstanceOptionalAuth.interceptors.response.use(
  genericSuccessHandler,
  genericErrorHandler
);

export const axiosInstanceNoAuth = axios.create({
  transformResponse: getAuthFromResponse,
  headers: { ...standardHeaders },
});

axiosInstanceNoAuth.interceptors.request.use((c) => c, genericErrorHandler);
axiosInstanceNoAuth.interceptors.response.use(
  genericSuccessHandler,
  genericErrorHandler
);

/**
 * N.b. only use this instance / functions for validated endpoints
 */
const axiosInstance = axios.create({
  transformResponse: getAuthFromResponse,
  headers: { ...standardHeaders },
});

axiosInstance.interceptors.request.use(setAuthOnRequest, genericErrorHandler);
axiosInstance.interceptors.response.use(
  genericSuccessHandler,
  genericErrorHandler
);

export default axiosInstance;
