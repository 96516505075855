import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { getFeatureFlag } from "../../API/async";

export interface FeatureFlags {
  splitPaymentsEnabled: boolean;
}

interface FeatureFlagsContextValue {
  featureFlags: FeatureFlags;
  isLoading: boolean;
}

enum FeatureFlagKeys {
  SplitPayments = "split_payments_shiplist",
}

const defaultFeatureFlags: FeatureFlagsContextValue = {
  featureFlags: {
    splitPaymentsEnabled: false,
  },
  isLoading: true,
};

export const FeatureFlagsContext = createContext<FeatureFlagsContextValue>(
  defaultFeatureFlags
);
export const getFeatureFlagsContext = (): FeatureFlagsContextValue =>
  useContext(FeatureFlagsContext);

export const FeatureFlagsProvider: React.FC = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(
    defaultFeatureFlags.featureFlags
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getFeatureFlags = async () => {
      try {
        const splitPaymentsEnabled = await getFeatureFlag(
          FeatureFlagKeys.SplitPayments
        );
        setFeatureFlags({ splitPaymentsEnabled });
      } finally {
        setIsLoading(false);
      }
    };

    getFeatureFlags();
  }, []);

  const value = useMemo(() => ({ featureFlags, isLoading }), [
    featureFlags,
    isLoading,
  ]);

  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
