import { AccountType } from "../../@types/account";
import { FormType } from "../../@types/form";
import { ProfileType } from "../../@types/profile";

export const transformAccountToProfile = (
  account: AccountType
): ProfileType => ({
  balance: account.attributes.accountBalance,
  createdAt: account.attributes.created_at,
  email: account.attributes.email,
  firstName: account.attributes.firstName,
  id: parseInt(account.id, 10),
  lastName: account.attributes.lastName,
  paymentMethod: account.attributes.paymentMethod as FormType,
  paypalAddress: account.attributes.paypalAddress,
  requestedStoreCredit:
    parseFloat(account.attributes.requestedStoreCredit) || 0.0,
  banned: account.attributes.banned,
  sellerBanned: account.attributes.sellerBanned,
  updatedAt: null,
  userId: parseInt(account.id, 10),
});
