import { FormType } from "../@types/form";
import { PaymentAdjustment } from "../@types/payment";

export type GetPaymentMethods = {
  [paymentMethod in FormType]?: PaymentAdjustment;
};

export const getPaymentMethods = (): GetPaymentMethods => ({
  ach: {
    processingFeeAmount: null,
    bonusPercent: null,
  },
  check: {
    processingFeeAmount: null,
    bonusPercent: null,
  },
  nm_gift_card: {
    processingFeeAmount: null,
    bonusPercent: 10,
  },
  paypal: {
    processingFeeAmount: null,
    bonusPercent: null,
  },
  account_balance: {
    processingFeeAmount: null,
    bonusPercent: 10,
  },
  wire_transfer: {
    processingFeeAmount: 50,
    bonusPercent: null,
  },
});
