import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRouter } from "next/router";
import {
  isRegionEU,
  SupportedLocale,
  SupportedRegion,
} from "../../helpers/consts/locale";
import { translations } from "../../locales/translations";
import { ProfileType } from "../../@types/profile";

export interface TranslateData {
  contextLocale: SupportedLocale;
  updateLocale: (locale: SupportedLocale) => void;
  translate: (str: string) => string;
  currentRegion: SupportedRegion;
}

export const TranslateContext = createContext<TranslateData>({
  contextLocale: null,
  updateLocale: null,
  translate: (str: string) => str,
  currentRegion: null,
});
export const getTranslateContext = (): TranslateData =>
  useContext(TranslateContext);

interface Props {
  children: React.ReactChild;
  user: Pick<ProfileType, "id" | "firstName" | "lastName">;
}

export type WithTranslate<P = {}> = P & { translateCtx: TranslateData };
export const withTranslate = <P extends {}>(
  Comp: React.FC<WithTranslate<P>>
): React.FC<P> => (props) => (
  <Comp translateCtx={getTranslateContext()} {...props} />
);

export const TranslateProvider = ({
  children,
  user,
}: Props): React.ReactElement => {
  const router = useRouter();
  const [contextLocale, setLocale] = useState(SupportedLocale.EN);

  const translate = (originalStr: string): string => {
    if (
      Object.values(SupportedLocale).includes(contextLocale) &&
      translations[contextLocale]?.[originalStr]
    ) {
      return translations[contextLocale][originalStr];
    }
    return originalStr;
  };

  const updateLocale = (locale: SupportedLocale): void => {
    if (contextLocale === locale) return;

    setLocale(locale);
    localStorage.setItem("locale", locale);
    router.push({ pathname: `/${locale}${router.pathname}` });
  };

  useEffect(() => {
    if (!isRegionEU) return;

    if (router.asPath?.includes(`/${SupportedLocale.EN}`)) {
      updateLocale(SupportedLocale.EN);
    } else if (router.asPath?.includes(`/${SupportedLocale.FR}`)) {
      updateLocale(SupportedLocale.FR);
    } else {
      const locale = localStorage.getItem("locale") as SupportedLocale;
      updateLocale(locale || SupportedLocale.EN);
    }
  }, [router.pathname]);

  const currentRegion = process.env.NEXT_PUBLIC_REGION as SupportedRegion;
  const translateContextValue = useMemo(
    () => ({
      contextLocale,
      updateLocale,
      translate,
      currentRegion,
    }),
    [contextLocale, updateLocale, translate, currentRegion]
  );

  return (
    <TranslateContext.Provider value={translateContextValue}>
      {children}
    </TranslateContext.Provider>
  );
};
