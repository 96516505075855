import {
  DispatchedEvent,
  IdentifyParams,
} from "@segment/analytics-next/dist/types/core/arguments-resolver";
import * as snippet from "@segment/snippet";
import Cookies from "js-cookie";
import { TrackingEvent } from "../../@types/events";
import { COOKIES } from "../cookies";
import { EventTypes } from "../mobileApp/mobileAppEvents.types";
import EventWhitelist from "./segmentWhitelist.json";

export const loadSegment = (
  isDNS = false
): ReturnType<typeof snippet["max"] | typeof snippet["min"]> => {
  const analyticsPreferences =
    (navigator as any)?.globalPrivacyControl || isDNS
      ? {
          load: {
            integrations: {
              "Data Lakes": true,
              Optimizely: true,
              "Google Analytics": false,
              "Actions Google Analytic 4": false,
              "Google AdWords New": false,
              "Google Enhanced Conversions": false,
              "Facebook Pixel": false,
              "Facebook Conversions API (Actions)": false,
              "Bing Ads": false,
              "Salesforce Marketing Cloud": false,
              "Impact Partnership Cloud": false,
              "Pinterest Tag": false,
              "Google Tag Manager": false,
              "Facebook CAPI Order Completed Function (Fashionphile)": false,
              "Google Analytics 4 Web": false,
            },
          },
        }
      : {};

  const options = {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    page: false,
    host: process.env.NEXT_PUBLIC_SEGMENT_HOST || "cdn.segment.com",
    ...analyticsPreferences,
  };

  const identityId = Cookies.get(COOKIES.UUID);
  if (identityId) {
    window.localStorage.setItem("ajs_anonymous_id", JSON.stringify(identityId));
    document.cookie = `ajs_anonymous_id=${encodeURIComponent(identityId)};`;
  }

  return process.env.NEXT_PUBLIC_ENV === "dev"
    ? snippet.max(options)
    : snippet.min(options);
};

/**
 * Checks for env var NEXT_PUBLIC_WHITELIST_EVENTS
 *
 * If `true`, checks against `EventWhitelist`
 *
 * use alike
 * ```
 * events.on(
 *  ...whitelistFilter(EVENT.foo, () => { ... })
 * )
 * ```
 */
export const segmentWhitelistFilter = (
  event: keyof TrackingEvent,
  eventCB: (...args: any) => Promise<any>
): [keyof TrackingEvent, (args: any) => Promise<any>] => {
  if (
    process.env.NEXT_PUBLIC_WHITELIST_SEGMENT_EVENTS === "true" &&
    !EventWhitelist[event]
  ) {
    return [event, () => null];
  }
  return [event, eventCB];
};

const isMobileApp = (): boolean => {
  const mobileAppCookie = Cookies.get(COOKIES.MOBILE_APP);
  return (
    mobileAppCookie === "1" ||
    (process && process.env?.NEXT_PUBLIC_MOBILE_APP_FORCE === "true")
  );
};

export const setSegmentId = (
  ...params: IdentifyParams
): Promise<DispatchedEvent> => window?.analytics?.identify(...params);
export const setAnonymousId = (id?: string): void => {
  if (!isMobileApp()) {
    window?.analytics?.setAnonymousId(id);
  }
};
export const resetSegment = (id?: string): void => {
  if (!isMobileApp()) {
    window?.analytics?.reset();
    setTimeout(() => {
      window?.analytics?.setAnonymousId(id);
    }, 500);
  }
};

interface CampaignTypes {
  [index: string]: unknown;
}

interface ContextTypes {
  campaign: CampaignTypes;
}

export const trackPage = (pageName, properties): void => {
  const context: ContextTypes = {
    campaign: {},
  };
  const params = new URLSearchParams(document.location.search) || null;

  if (params) {
    const utmSource = params.get("utm_source");
    const utmMedium = params.get("utm_medium");
    const utmCampaign = params.get("utm_campaign");
    const utmTerm = params.get("utm_term");
    const utmContent = params.get("utm_content");

    if (utmSource) {
      context.campaign.source = utmSource;
    }
    if (utmMedium) {
      context.campaign.medium = utmMedium;
    }
    if (utmCampaign) {
      context.campaign.name = utmCampaign;
    }
    if (utmTerm) {
      context.campaign.term = utmTerm;
    }
    if (utmContent) {
      context.campaign.content = utmContent;
    }
  }

  const pageHasContextValues = Object.values(context.campaign).length > 0;

  if (isMobileApp()) {
    window.dispatchEvent(
      new CustomEvent(EventTypes.ANALYTICS_PAGE_VIEW_EVENT, {
        detail: {
          properties: {
            name: pageName,
            ...properties,
          },
        },
      })
    );
  } else if (pageHasContextValues) {
    window?.analytics?.page(pageName, properties, context);
  } else {
    window?.analytics?.page(pageName, properties);
  }
};
export const trackEvent = (event, properties): void => {
  if (isMobileApp()) {
    window.dispatchEvent(
      new CustomEvent(EventTypes.ANALYTICS_TRACK_EVENT, {
        detail: {
          event,
          properties,
        },
      })
    );
  } else {
    window?.analytics?.track(event, properties);
  }
};
